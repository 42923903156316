import { createGetKcContext } from 'keycloakify/login';

export const { getKcContext } = createGetKcContext();

export const { kcContext } = getKcContext({
  // Uncomment to test the login page for development.
  // mockPageId: 'login.ftl',
});

export type KcContext = NonNullable<
  ReturnType<typeof getKcContext>['kcContext']
>;
