import type { AnalyticsBrowser } from '@segment/analytics-next';
import * as Sentry from '@sentry/react';
import postHog, { type PostHog } from 'posthog-js';
import type { SegmentAnalytics } from 'posthog-js/lib/src/extensions/segment-integration';
import { PostHogProvider, useActiveFeatureFlags } from 'posthog-js/react';
import {
  createContext,
  type ReactNode,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useAnalyticsInitialization } from '../analytics/hooks';

declare global {
  interface Window {
    posthog: PostHog;
  }
}

const MAXIMUM_POSTPONE_TIME_MS = 1000;
const MAXIMUM_WAIT_FOR_EXPERIMENTS = 1000;
const SANITIZED_PATHNAME = '/sanitized';

type PostponeInitializationFunc = (promise: Promise<void>) => void;

type ExperimentName = 'onboarding_default_autoscaling_range';

type FeatureFlagsStore = Record<string, boolean | string | undefined>;

interface PostHogInfo {
  isEnabled: boolean;
  isInitialized: boolean;
  isFeatureFlagsLoaded: boolean;
  bootstrappedFeatureFlags: FeatureFlagsStore;
  setInitialized: () => void;
}

const postHogInfoContext = createContext<PostHogInfo>({
  isEnabled: false,
  isInitialized: false,
  isFeatureFlagsLoaded: false,
  bootstrappedFeatureFlags: {},
  setInitialized: () => {},
});

async function timeout(
  timeoutMs: number,
  targetPromise: Promise<unknown>,
  timeoutCallback: () => void,
): Promise<void> {
  let timeoutId: number | undefined;

  await Promise.race([
    targetPromise,
    new Promise<void>((resolve) => {
      timeoutId = window.setTimeout(() => {
        timeoutCallback();
        resolve();
      }, timeoutMs);
    }),
  ]);

  window.clearTimeout(timeoutId);
}

export interface PostHogConfig {
  apiKey: string;
  flags: Record<string, string | boolean>;
}

interface PostHogCustomProviderProps {
  analytics?: AnalyticsBrowser;
  postponeInitialization?: PostponeInitializationFunc;
  postHogConfig?: PostHogConfig;
  userId?: string;
  children: ReactNode;
}

const PostHogCustomProvider = ({
  analytics,
  postponeInitialization,
  postHogConfig,
  children,
  userId,
}: PostHogCustomProviderProps) => {
  const isAlreadyInitRef = useRef(false);
  const resolveRef = useRef<(() => void) | undefined>();

  const { setInitialized } = useContext(postHogInfoContext);

  useLayoutEffect(() => {
    if (!isAlreadyInitRef.current && postponeInitialization) {
      postponeInitialization(
        new Promise<void>((resolve) => {
          resolveRef.current = resolve;
          window.setTimeout(resolve, MAXIMUM_POSTPONE_TIME_MS);
        }),
      );
      isAlreadyInitRef.current = true;
    }
  }, [postponeInitialization]);

  useEffect(() => {
    async function init() {
      if (!postHogConfig?.apiKey) {
        resolveRef.current?.();
        return;
      }

      if (analytics) {
        await timeout(2000, analytics.ready(), () => {
          Sentry.addBreadcrumb({
            level: 'warning',
            message: 'Initializing of analytics took more than 2 sec',
          });
        });
      }

      try {
        postHog.init(postHogConfig.apiKey, {
          api_host: 'https://app.posthog.com',
          segment: analytics?.instance as SegmentAnalytics | undefined,
          capture_pageview: false,
          autocapture: false,
          // NOTE: Posthog by default stores some information in a cookie.
          // This gets problematic as cookie value grows in size. Our nginx proxy
          // will reject requests with large cookie values. So if you need
          // persistence than make sure it is not stored in a cookie!
          disable_persistence: true,
          persistence: 'memory',
          ip: false,
          // This stops posthog from sending a request to evaluate flags in the cloud.
          // advanced_disable_decide: true,
          // Bootstrapping means that no requests are sent from the client side
          // when initializing posthog SDK. Backend forwards all feature flags
          // to the UI.
          bootstrap: {
            distinctID: userId,
            isIdentifiedID: Boolean(userId),
            // These are user feature flags
            featureFlags: postHogConfig?.flags,
          },
          loaded: () => {
            setInitialized();
            resolveRef.current?.();

            // Only for debug via DevTools purposes
            if (window.location.host !== 'console.neon.tech') {
              window.posthog = postHog;
            }
          },
          session_recording: {
            maskTextSelector: '*',
            maskAllInputs: true,
          },
          sanitize_properties: (properties) => {
            if (properties.$current_url) {
              // eslint-disable-next-line no-param-reassign
              properties.$current_url = `${document.location.origin}${SANITIZED_PATHNAME}`;
            }
            if (properties.$pathname) {
              // eslint-disable-next-line no-param-reassign
              properties.$pathname = SANITIZED_PATHNAME;
            }
            if (properties.path) {
              // eslint-disable-next-line no-param-reassign
              properties.path = SANITIZED_PATHNAME;
            }
            return properties;
          },
        });
      } catch (error) {
        resolveRef.current?.();
        // eslint-disable-next-line no-console
        console.error(error);
        Sentry.captureException(error);
      }
    }

    void init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analytics, postHogConfig, userId]);

  return <PostHogProvider>{children}</PostHogProvider>;
};

const PostHogInfoProvider = postHogInfoContext.Provider;

interface ExperimentsProviderProps {
  isEnabled: boolean;
  postHogConfig?: PostHogConfig;
  children: ReactNode;
  userId?: string;
}

export const ExperimentsProvider = ({
  isEnabled,
  postHogConfig,
  children,
  userId,
}: ExperimentsProviderProps) => {
  const { analytics, postponeInitialization } = useAnalyticsInitialization();

  const [isPostHogEnabled] = useState(
    Boolean(isEnabled && postHogConfig?.apiKey),
  );

  const [isLoaded, setIsLoaded] = useState(!isPostHogEnabled);

  const [isInitialized, setIsInitialized] = useState(false);

  const postHogInfo = useMemo<PostHogInfo>(
    () => ({
      isEnabled: isPostHogEnabled,
      isInitialized,
      isFeatureFlagsLoaded: isLoaded,
      bootstrappedFeatureFlags: postHogConfig?.flags ?? {},
      setInitialized: () => setIsInitialized(true),
    }),
    [isInitialized, isPostHogEnabled, isLoaded, postHogConfig],
  );

  const activeFlags = useActiveFeatureFlags();
  const isActiveFlagsLoaded = Boolean(activeFlags);

  if (isActiveFlagsLoaded && !isLoaded) {
    setIsLoaded(true);
  }

  useEffect(() => {
    const timeoutId = window.setTimeout(() => {
      setIsLoaded(true);
    }, MAXIMUM_WAIT_FOR_EXPERIMENTS);

    return () => {
      window.clearTimeout(timeoutId);
    };
  }, []);

  return (
    <PostHogInfoProvider value={postHogInfo}>
      <PostHogCustomProvider
        postHogConfig={isPostHogEnabled ? postHogConfig : undefined}
        analytics={analytics}
        postponeInitialization={postponeInitialization}
        userId={userId}
      >
        {children}
      </PostHogCustomProvider>
    </PostHogInfoProvider>
  );
};

export function useBootstrappedFeatureFlags(): FeatureFlagsStore {
  return useContext(postHogInfoContext).bootstrappedFeatureFlags;
}

export function useBootstrappedFeatureFlag(
  featureFlagName: string,
): boolean | string | undefined {
  return useBootstrappedFeatureFlags()[featureFlagName];
}

// This hook allows to get activated variant of a selected by name experiment.
// "undefined" will be returned while feature flags are loading.
// The full documentation can be found here: docs/frontend/experiments.md
export function useExperiment(
  experimentName: ExperimentName,
): undefined | string {
  const { isFeatureFlagsLoaded } = useContext(postHogInfoContext);
  const featureFlagValue = useBootstrappedFeatureFlag(experimentName);

  if (featureFlagValue === undefined) {
    if (isFeatureFlagsLoaded) {
      return 'control';
    }
    return undefined;
  }

  // Experiments works only with strings
  if (typeof featureFlagValue !== 'string') {
    return 'control';
  }

  return featureFlagValue;
}
