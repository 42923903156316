import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';

import { KcApp as KcLoginThemeApp } from './login/KcApp';
import { kcContext as kcLoginThemeContext } from './login/kcContext';

const root = createRoot(document.getElementById('root')!);
root.render(
  // Disabling `StrictMode` because it's much stricter in React 18
  // (it mounts everything twice)
  // <StrictMode>
  <Suspense fallback={null}>
    {(() => {
      if (kcLoginThemeContext !== undefined) {
        return <KcLoginThemeApp kcContext={kcLoginThemeContext} />;
      }

      throw new Error(
        'This app is a Keycloak theme' +
          "It isn't meant to be deployed outside of Keycloak",
      );
    })()}
  </Suspense>,
  // </StrictMode>
);
