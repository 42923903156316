import { Button } from '@neondatabase/glow';

import style from './GoToHome.module.css';

const WEBSITE_HOME_URL = 'https://neon.tech/home';

export const GoToHomeButton = () => (
  <Button
    as="a"
    role="button"
    href={WEBSITE_HOME_URL}
    className={style.button}
    icon="arrow_left"
    appearance="secondary"
  >
    Home
  </Button>
);
