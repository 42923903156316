import type { EventProperties } from '@segment/analytics-next';
import { isNil, omitBy } from 'lodash-es';
import { createContext, type ReactNode, useContext } from 'react';

type ExtraPropertiesContext = EventProperties & { isLoading?: boolean };

const extraPropertiesContext = createContext<ExtraPropertiesContext>({});

export const ExtraAnalyticsPropertiesProvider = ({
  children,
  isLoading,
  ...extraProperties
}: { children: ReactNode } & ExtraPropertiesContext) => {
  const { isLoading: previousIsLoading, ...previousExtraProperties } =
    useContext(extraPropertiesContext);

  return (
    <extraPropertiesContext.Provider
      value={{
        isLoading: isLoading || previousIsLoading,
        ...previousExtraProperties,
        ...omitBy(extraProperties, isNil),
      }}
    >
      {children}
    </extraPropertiesContext.Provider>
  );
};

export function useExtraProperties() {
  const extraProperties = useContext(extraPropertiesContext);
  return extraProperties;
}
