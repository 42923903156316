import classNames from 'classnames';

import { Icon } from '../Icon';

import styles from './Loader.module.css';

export interface LoaderProps {
  size?: number;
  className?: string;
}

export const Loader = ({ size = 24, className }: LoaderProps) => (
  <div className={classNames(className, styles.root)}>
    <Icon name="spinner" size={size} />
  </div>
);

export const CenteredLoader = ({ className, ...props }: LoaderProps) => (
  <div className={classNames(className, styles.centeredContainer)}>
    <Loader {...props} />
  </div>
);
