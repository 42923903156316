import cn from 'classnames';
import { forwardRef, type ReactNode } from 'react';

import styles from './Heading.module.css';

export type HeadingLevel = 1 | 2 | 3 | 4 | 5 | 6;

export type HeadingSize =
  | 'huge'
  | 'large'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6';

const sizes: Record<HeadingSize, string> = {
  huge: styles.root_huge,
  large: styles.root_large,
  h1: styles.root_h1,
  h2: styles.root_h2,
  h3: styles.root_h3,
  h4: styles.root_h4,
  h5: styles.root_h5,
  h6: styles.root_h6,
};

function getSizeBasedOnLevel(level: HeadingLevel): HeadingSize {
  switch (level) {
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
      return `h${level}`;
    default:
      return 'h6';
  }
}

interface HeadingProps {
  level: HeadingLevel;
  size?: HeadingSize;
  className?: string;
  children: ReactNode;
}

export const Heading = forwardRef<HTMLHeadingElement, HeadingProps>(
  ({ level, size = getSizeBasedOnLevel(level), className, children }, ref) => {
    const HeadingTag = `h${level}` as const;

    return (
      <HeadingTag
        ref={ref}
        className={cn(styles.root, className, sizes[size])}
        role="heading"
      >
        {children}
      </HeadingTag>
    );
  },
);

Heading.displayName = 'Heading';
