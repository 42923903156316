import type { AnalyticsBrowser } from '@segment/analytics-next';
import { createContext, type RefObject } from 'react';

export interface CustomAnalyticsBrowserContextValue {
  analytics?: AnalyticsBrowser;
  enabled: boolean;
  section: string;
  currentPageIdRef: RefObject<string | undefined>;
  setCurrentPageId: (pageId: string) => void;
  pageMapper: { [key: string]: string };
  enqueueAction: (func: () => void) => void;
  postponeInitialization?: (promise: Promise<void>) => void;
}

export const AnalyticsContext =
  createContext<CustomAnalyticsBrowserContextValue>({
    analytics: undefined,
    enabled: false,
    currentPageIdRef: { current: undefined },
    setCurrentPageId: () => {},
    section: '',
    pageMapper: {},
    enqueueAction: (callback) => callback(),
    postponeInitialization: undefined,
  });
AnalyticsContext.displayName = 'AnalyticsContext';
