import classNames from 'classnames';
import {
  type ForwardedRef,
  forwardRef,
  type HTMLAttributes,
  memo,
} from 'react';

import { isKeyOf } from '../../utils/isKeyOf';
import { type Theme, useTheme } from '../ThemeProvider/ThemeProvider';

import styles from './Icon.module.css';
import * as PAINTED_ICONS from './paintedIcons';
import * as PLAIN_ICONS from './plainIcons';

type PlainIconNames = keyof typeof PLAIN_ICONS;
type PaintedIconNames = keyof typeof PAINTED_ICONS;

const PLAIN_ICON_NAMES = Object.keys(PLAIN_ICONS) as PlainIconNames[];

const PAINTED_ICON_NAMES = Object.keys(PAINTED_ICONS) as PaintedIconNames[];

export type IconName = PlainIconNames | PaintedIconNames;

const ALL_ICON_NAMES = [
  ...PLAIN_ICON_NAMES,
  ...PAINTED_ICON_NAMES,
] as IconName[];

export function isIconName(name: string): name is IconName {
  return (ALL_ICON_NAMES as string[]).includes(name);
}

export interface IconProps extends HTMLAttributes<HTMLDivElement> {
  name: IconName;
  size: number;
  forcedTheme?: Theme;
}

const IconC = forwardRef(
  (
    { name, size, forcedTheme, ...props }: IconProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const theme = useTheme();

    const Icon = isKeyOf(PAINTED_ICONS, name)
      ? PAINTED_ICONS[name][forcedTheme || theme]
      : PLAIN_ICONS[name];

    return (
      <span
        {...props}
        className={classNames(props.className, styles.root, {
          [styles.root_success]: name === 'success',
        })}
        style={{
          width: `${size}px`,
          height: `${size}px`,
        }}
        ref={ref}
      >
        <Icon width={`${size}px`} height={`${size}px`} />
      </span>
    );
  },
);
IconC.displayName = 'IconC';

export const Icon = memo(IconC);
