import {
  createContext,
  type PropsWithChildren,
  useContext,
  useEffect,
} from 'react';

export const enum Theme {
  Light = 'light',
  Dark = 'dark',
}

const themeContext = createContext<Theme | null>(null);

export function useTheme(): Theme {
  const theme = useContext(themeContext);

  if (!theme) {
    throw new Error('useTheme() was called outside of a ThemeProvider');
  }

  return theme;
}

export interface ThemeProviderProps {
  theme: Theme;
}

export const ThemeProvider = ({
  theme,
  children,
}: PropsWithChildren<ThemeProviderProps>) => {
  useEffect(() => {
    document.body.classList.add(`${theme}-theme`);
    return () => {
      document.body.classList.remove(`${theme}-theme`);
    };
  }, [theme]);

  return (
    <themeContext.Provider value={theme}>{children}</themeContext.Provider>
  );
};
